<template>
  <div class="px-2 applications">
    <div class="d-flex align-items-center card-wrapper">
      <div
        offset-sm-4
        sm="8"
        md="6"
        lg="6"
        class="d-flex w-50 breadcrumb-wrapper justify-content-start"
      >
        <BreadCrumb
          :bread-crumb-items="breadCrumbItems"
        />
      </div>
      <div
        offset-sm-4
        sm="8"
        md="6"
        lg="6"
        class="d-flex w-50 font-weight-bold button-style justify-content-end button-wrapper"
      >
        <span
          v-if="getMemberRoleInSelectedGroup === MemberType.OWNER"
          class="text-gray justify-content-end px-1"
        >
          <b-button
            variant="primary"
            class="mb-md-0 mb-1 buttons"
            @click="goToBillingScreen"
          >
            Billing Details
          </b-button>
        </span>
        <span
          v-if="getMemberRoleInSelectedGroup !== MemberType.MEMBER"
          class="text-gray justify-content-end px-1"
        >
          <b-button
            variant="primary"
            class="buttons"
            @click="getGroupMembers"
          >
            Business Details
          </b-button>
        </span>
      </div>
    </div>

    <!-- pricing plan cards -->
    <b-row class="pricing-card m-2 mt-0 mt-md-5">
      <b-col
        offset-sm-2
        sm="6"
        md="12"
        lg="12"
        class="mx-auto"
      >
        <b-row class="d-flex justify-content-center mt-2">
          <span
            v-for="(application, index) in applicationName"
            :key="index"
          >
            <b-card
              align="center"
              class="m-1 mt-4 cursor-pointer"
              @click="goToApplicationPage(application.name)"
            >
              <div class="align-items-center">
                <div class="mt-1">
                  <img
                    :src="application.icon"
                    alt="application-icon"
                    style="max-width: 114px;"
                  >
                </div>
                <span
                  class="ml-1 font-weight-bolder group-name-class text-capitalize"
                >
                  <div>{{ application.name }}</div>
                </span>
              </div>
              <!--/ img -->
            </b-card>
          </span>
        </b-row>
      </b-col>
    </b-row>
  </div>
  <!--/ pricing plan cards -->
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import PackageType from '@/common/enums/packagesEnum'
import MemberType from '@/common/enums/memberTypeEnum'
import { showToast } from '@/common/global/functions'
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BreadCrumb,
  },
  directives: {
    Ripple,
  },
  async beforeRouteEnter(to, from, next) {
    const userGroups = await store.getters['user/getGroups']
    if (userGroups.length <= 0) {
      next({
        name: 'pricing-plans',
      })
      return
    }
    if (!to.query.groupId) {
      next({
        name: 'groups-listing',
      })
      return
    }
    next()
  },
  data() {
    return {
      MemberType,
      applicationName: [],
      PackageType,
      selectedGroupId: null,
      SimiSmart: require('@/assets/images/simiicons/simi-logo-icon-109.png'),
      simiPowerSaving: require('@/assets/images/simiicons/Simi Power S.png'),
      breadCrumbItems: [
        { name: 'Home', to: '/', active: false },
        { name: 'Businesses', to: '/groups-listing', active: false },
        { name: 'Applications', to: null, active: true },
      ],
    }
  },
  computed: {
    getSelectedGroupAvailableApplications() {
      return this.$store.getters['user/getSelectedGroupAvailableApplications']
    },
    getSelectedGroupMember() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
    getMemberRoleInSelectedGroup() {
      return this.getSelectedGroupMember
        ? this.getSelectedGroupMember.role
        : null
    },
    getSelectedGroupStatus() {
      return this.$store.getters['user/getSelectedGroupMemberStatus']
    },
  },
  async mounted() {
    this.selectedGroupId = this.$route.query.groupId
    await this.setApplications()
    await this.$store.dispatch('user/setAllUserGroupIfNotPresent')
  },
  methods: {
    setApplications() {
      if (this.getSelectedGroupAvailableApplications.isSimiIot) {
        this.applicationName.push({
          name: 'Simi Smart',
          icon: require('@/assets/images/simiicons/simi-logo-icon.png'),
        })
      }
      if (this.getSelectedGroupAvailableApplications.isSimiLauncher) {
        this.applicationName.push({
          name: 'Simi Digital Platform',
          icon: require('@/assets/images/logo/simi-logo-icon.png'),
        })
      }
      if (this.getSelectedGroupAvailableApplications.isSimiPowerSaver) {
        this.applicationName.push({
          name: 'Simi Power Saver',
          icon: require('@/assets/images/simiicons/Simi Power S.png'),
        })
      }
    },
    getGroupMembers() {
      this.$router.push({
        path: '/group-members',
        query: { groupId: `${this.selectedGroupId}` },
      })
    },
    goToBillingScreen() {
      this.$router.push({
        path: '/billing-details',
        query: { groupId: `${this.selectedGroupId}` },
      })
    },
    async goToApplicationPage(applicationName) {
      if (!this.getSelectedGroupStatus) {
        if (this.getMemberRoleInSelectedGroup === MemberType.OWNER) {
          await showToast('Applications', 'Group subscription is expired!', 'danger', 4000)
          await this.$router.push({
            path: '/billing-details',
            query: { groupId: `${this.selectedGroupId}` },
          })
        } else {
          showToast('Applications', 'Group subscription is expired!', 'danger', 4000)
        }
      } else if (applicationName === 'Simi Power Saver') {
        this.openSwal('Simi Power Saver', 'Please download our Simi Power Saver App.', this.simiPowerSaving)
      } else if (applicationName === 'Simi Smart') {
        this.openSwal('Simi Smart', 'Please download our Simi Smart App.', this.SimiSmart)
      } else {
        window.open(
          `${process.env.VUE_APP_SIMI_LAUNCHER_BASE_URL}launcher?groupId=${this.selectedGroupId}`,
          '_blank',
        )
      }
    },
    openSwal(title, text, icon) {
      this.$swal({
        title: `<span class="font-weight-bolder">${title}</span>`,
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        imageUrl: icon,
        text,
        confirmButtonText: 'Close',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          icon: 'primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
//@import '@core/scss/vue/pages/page-pricing.scss';
.applications {
  .card {
    min-width: 400px !important;
    border-radius: 16px !important;
    @media (max-width: 921px) {
      min-width: 340px !important;
    }
    @media (max-width: 919px) {
      min-width: 300px !important;
    }
  }
  .card-body {
    padding: 1rem !important;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
    margin-bottom: 2rem !important;
  }
  .group-name-class {
    font-size: large;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
  }
  .breadcrumb-item.active {
    color: gray !important;
  }
}
@media (max-width: 770px) {
  .card-wrapper{
    flex-direction: column;
    justify-content: flex-start;
  }
  .breadcrumb-wrapper{
    width: 100% !important;
  }
  .button-wrapper{
    width: 100% !important;
  }
}
@media (max-width: 550px) {
.button-wrapper{
  justify-content: start !important;
}
.buttons{
    padding: 9px 9px;
    font-size: 12px;
  }
}
</style>
